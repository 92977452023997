import axios from './axios.js'
// 通用api，包括登录等
const apiList = {
  // 登录
  login: '/auth/login',
  // 分页查询科目
  listCourse: '/fq-course/pageQueryCourse',
  // 科目选择查询
  selectCourse: '/fq-course/queryCourse',
  // 添加科目
  addCourse: '/fq-course/saveOrUpdateCourse',
  // 修改科目信息
  updateCourse: '/fq-course/saveOrUpdateCourse',
  // 修改和添加任务信息
  updateTask: '/test-task/saveOrUpdateTestTask',
  // 删除科目
  deleteCourse: '/fq-course/deleteCourse',
  // 查看任务
  showTask: '/test-task/queryCourseTask',
  // 删除任务
  deleteTask: '/test-task/deleteTestTask',
  // 删除题库
  deleteQuestion: '/bank/deleteQuestion',
  // 查询题库
  showQuestion: '/bank/PageQuestionBank',
  // 没有答案的题目
  insertQuestion: '/question/uninput-question/pageQueryUnInputQuestion',
  // 学生管理列表
  listStudent: '/student/pageQueryStudent',
  // 补录题目
  insertTask: '/answer/supplementQuestionAnswer',
  // 查找答案
  // queryAnswer: '/answer/queryAnswer'
  queryAnswer: '/question/uninput-question/insertUnInsertQuestion',
  // 补录题目列表
  listReQuestion: '/question/uninput-question/pageQueryUnInputQuestion',
  // 分页查询答题记录
  listAnswerinfo: '/answer-record/pageQueryAnswerRecord',
  // 分页查询学生(考生)
  pageQueryStudent: '/student-task/pageQueryStudent',
  // 批量更改学生状态
  updateStatus: '/student-task/updateStudentTask',
  // 按科目批量更改学生状态
  updateOrStuIdStatus: '/student-task/updateStuStatusByIds',
  // 按科目和学号批量查询学生状态
  showQueryStudent: '/student-task/queryStuByUserNames',
  // 根据学号批量查询学生状态
  showQueryListStudent: '/student-task/queryStuByUserListNames',
  // 修改学生信息
  updateStudentInfo: '/student/editStudent',
  // 考场记录列表
  queryTestRecordPage: '/question/test-record/queryTestRecordPage',
  // 考试记录列表
  queryExaminfo: '/question/exam-record/queryPageExamRecord',
  // 管理员登录
  adminUser: '/question/user/login',
  // 查询所有任务
  queryTestTask: '/test-task/queryTestTask',
  // 获取所有批次
  getAllBatchs: '/student/queryAllBatch'

}
export default {
  apiList,
  // 登录
  login (data = {}) {
    return axios({
      url: apiList.login,
      method: 'post',
      data
    })
  },
  listCourse (data = {}) {
    return axios({
      url: apiList.listCourse,
      method: 'post',
      data
    })
  },
  selectCourse (data = {}) {
    return axios({
      url: apiList.selectCourse,
      method: 'get',
      data
    })
  },
  addCourse (data = {}) {
    return axios({
      url: apiList.addCourse,
      method: 'post',
      data
    })
  },
  deleteCourse (data = {}) {
    return axios({
      url: apiList.deleteCourse,
      contentType: 'json',
      method: 'post',
      data
    })
  },
  updateCourse (data = {}) {
    return axios({
      url: apiList.updateCourse,
      method: 'post',
      data
    })
  },
  updateTask (data = {}) {
    return axios({
      url: apiList.updateTask,
      method: 'post',
      data
    })
  },
  deleteTask (data = {}) {
    return axios({
      url: apiList.deleteTask,
      // contentType: 'application/json;charset:utf-8',
      method: 'post',
      data
    })
  },
  deleteQuestion (data = {}) {
    return axios({
      url: apiList.deleteQuestion,
      method: 'post',
      data
    })
  },
  showTask (data = {}) {
    return axios({
      url: apiList.showTask,
      method: 'post',
      data
    })
  },
  showQuestion (data = {}) {
    return axios({
      url: apiList.showQuestion,
      method: 'post',
      data
    })
  },
  insertQuestion (data = {}) {
    return axios({
      url: apiList.insertQuestion,
      method: 'post',
      data
    })
  },
  listReQuestion (data = {}) {
    return axios({
      url: apiList.listReQuestion,
      method: 'post',
      data
    })
  },
  allCourseName (data = {}) {
    return axios({
      url: apiList.allCourseName,
      method: 'get',
      data
    })
  },
  listStudent (data = {}) {
    return axios({
      url: apiList.listStudent,
      contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  pageQueryStudent (data = {}) {
    return axios({
      url: apiList.pageQueryStudent,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  insertTask (data = {}) {
    return axios({
      url: apiList.insertTask,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  listAnswerinfo (data = {}) {
    return axios({
      url: apiList.listAnswerinfo,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  updateStatus (data = {}) {
    return axios({
      url: apiList.updateStatus,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  updateOrStuIdStatus (data = {}) {
    return axios({
      url: apiList.updateOrStuIdStatus,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  showQueryStudent (data = {}) {
    return axios({
      url: apiList.showQueryStudent,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  showQueryListStudent (data = {}) {
    return axios({
      url: apiList.showQueryListStudent,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  updateStudentInfo (data = {}) {
    return axios({
      url: apiList.updateStudentInfo,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  queryTestRecordPage (data = {}) {
    return axios({
      url: apiList.queryTestRecordPage,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  queryExaminfo (data = {}) {
    return axios({
      url: apiList.queryExaminfo,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  queryAnswer (data = {}) {
    return axios({
      url: apiList.queryAnswer,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  adminUser (data = {}) {
    return axios({
      url: apiList.adminUser,
      // contentType: 'application/json;charset=UTF-8',
      method: 'post',
      data
    })
  },
  queryTestTask (data = {}) {
    return axios({
      url: apiList.queryTestTask,
      method: 'get',
      data
    })
  },
  getAllBatchs (data = {}) {
    return axios({
      url: apiList.getAllBatchs,
      method: 'get',
      data
    })
  }
}
