import axios from 'axios'
import { Message } from 'element-ui'

/**
 * @params
 * url: 请求地址(必须)
 * method: 请求方式（必须）
 * data: 请求参数（非必须）
 * formData: Boolean,是否转换成formData(非必须)
 */

const noRepeatSet = new Set()

// 错误消息是否在显示中
let isErrorShowing = false

const changeDataToFormData = (data, formData) => {
  const append = (key, value = '') => {
    const isFile = value instanceof File || value instanceof Blob
    if (typeof value === 'object' && value !== null && !isFile) {
      Object.entries(value).forEach(item => {
        if (Array.isArray(value)) {
          if (item[1] instanceof File || item[1] instanceof Blob) {
            append(key, item[1])
          } else {
            append(`${key}[${item[0]}]`, item[1])
          }
        } else {
          append(`${key}.${item[0]}`, item[1])
        }
      })
    } else {
      const val = value === null ? '' : value
      if (isFile) {
        formData.append(key, val, val.name)
      } else {
        formData.append(key, val)
      }
    }
  }
  Object.entries(data).forEach(item => {
    append(item[0], item[1])
  })
}

axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token')
    const params = config.params || config.data
    if (params.headers) {
      config.headers = {
        ...config.headers,
        ...params.headers
      }
      delete params.headers
    }
    if (token) {
      config.headers.token = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    switch (response.data.code) {
      case 401: {
        window.location.hash = '#/Login'
        break
      }
    }
    switch (response.status) {
      case 200: {
        return response
      }
      case 201: {
        return response
      }
    }
  },
  error => {
    // debugger
    if (error.response) {
      switch (error.response.status) {
        case 400:
        case 401: {
          window.location.hash = '/'
          return Promise.reject(new Error('登录信息过期，请重新登录'))
        }
        case 402: {
          window.location.hash = '/'
          return Promise.reject(new Error('用户登录凭证已失效，请从新登录'))
        }
        case 403: {
          break
        }
        case 404: {
          break
        }
        case 502: {
          return Promise.reject(new Error('服务端未启动，请检查'))
        }
      }
    }
    return Promise.reject(error)
  }
)

// http请求
const httpServer = opts => {
  // get请求公共参数
  const publicParams = {
    // ts: Date.now()
  }
  // 防止重复提交某些数据
  if (opts.noRepeat) {
    if (noRepeatSet.has(opts.url)) {
      Message.info('不可重复提交数据')
      return Promise.resolve(false)
    } else {
      noRepeatSet.add(opts.url)
    }
  }
  // http默认配置
  const method = opts.method.toUpperCase()
  const httpDefaultOpts = {
    headers: {
      token: sessionStorage.getItem('token')
    },
    ContentType: 'application/json',
    method,
    baseURL: process.env.VUE_APP_SERVER_URL + '/api',
    url: opts.url,
    timeout: 20000
  }
  const dataRequest = ['PUT', 'POST', 'PATCH']
  if (dataRequest.includes(method) && !opts.contentType) {
    httpDefaultOpts.data = opts.data || {}
  } else {
    httpDefaultOpts.params = {
      ...publicParams,
      ...(opts.data || {})
    }
  }

  // formData转换
  if (opts.formData) {
    httpDefaultOpts.transformRequest = [
      data => {
        const formData = new FormData()
        changeDataToFormData(data, formData)
        return formData
      }
    ]
  }

  return new Promise((resolve, reject) => {
    axios(httpDefaultOpts).then(res => {
      if (opts) {
        noRepeatSet.delete(opts.url)
      }
      if (res.data) {
        resolve(res.data.data)
      } else {
        const msg = res.data.data || ''
        Message.error(msg.length > 100 ? '操作出错' : msg)
        resolve(false)
      }
    }).catch(err => {
      if (isErrorShowing === false) {
        isErrorShowing = true
        if (err.message.includes('Network Error')) {
          Message.error('网络错误')
        } else if (err.message.startsWith('timeout')) {
          Message.error('请求超时')
        } else if (err.message.includes('请')) {
          Message.error(err.message)
        } else {
          let msg = '服务端接口出错'
          if (err.response && err.response.data) {
            const { message, error } = err.response.data
            if (message && message.length < 100) {
              msg = message
            } else {
              msg = error
            }
          }
          Message.error(msg)
        }
        setTimeout(() => {
          isErrorShowing = false
        }, 3000)
      }
      resolve(false)
    }).finally(() => {
      if (opts.immediatelyClear) {
        noRepeatSet.delete(opts.url)
      } else {
        setTimeout(() => {
          if (opts.noRepeat) {
            noRepeatSet.delete(opts.url)
          }
        }, 500)
      }
    })
  })
}

export default httpServer
